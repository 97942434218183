import { Component } from '@angular/core';
import { ITask } from '../../../../deno/interfaces/task';
import { Api } from './services/api';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  task: ITask = {
    turnOnLights: [],
    turnOffLights: [],
    pump: {
      durationSeconds: 10
    }
  }

  lightStates = [false, false, false, false];

  constructor(private api: Api) {

  }




  async setTask() {
    this.task.turnOffLights = [];
    this.task.turnOnLights = [];
    for (let index = 0; index < this.lightStates.length; index++) {
     if(this.lightStates[index])
      {
        this.task.turnOnLights.push(index);
      }
      else
      {
        this.task.turnOffLights.push(index);
      }

    }
    await this.api.public().post('/set-task', this.task);
  }

}
