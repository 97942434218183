<div>
  <div>
    Pump: <input type="number" [(ngModel)]="task.pump!.durationSeconds" placeholder="pump">
  </div>
  <div (click)="lightStates[0] = !lightStates[0]" [ngClass]="{'green':lightStates[0]}">
    Lampa 1
  </div>
  <div (click)="lightStates[1] = !lightStates[1]" [ngClass]="{'green':lightStates[1]}">
    Lampa 2
  </div>
  <div (click)="lightStates[2] = !lightStates[2]" [ngClass]="{'green':lightStates[2]}">
    Lampa 3
  </div>
  <div (click)="lightStates[3] = !lightStates[3]" [ngClass]="{'green':lightStates[3]}">
    Lampa 4
  </div>
  <button (click)="setTask()">Skicka</button>
</div>
